<template>
  <NavQuestion
    :heading="heading"
    :number="'2'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch :checked="details.gifts_before" @toggle="save"></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/gifts/leave_option'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'GiftsDirectOption',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    isValidated() {
      return true
    },
    heading() {
      return "Would you like to leave any 'direct' gifts?"
    },
    partner() {
      return this.$store.getters.partner
    },
    subHeading() {
      return (
        "These 'direct' gifts will pass even if " +
        this.relationYour(this.partner.relationship) +
        ' is still alive - for example, one might leave a ' +
        'gift of jewellery directly to their daughter. Gifts that pass only if ' +
        this.partner.full_name +
        ' has passed away before you are called ' +
        "'second death' gifts (these are dealt with in the next section)."
      )
    },
    forwardTo() {
      if (this.details.gifts_before) return '/gifts/direct'
      return '/gifts/indirect_option'
    },
    details() {
      return this.$store.getters.details
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          gifts_before: !this.details.gifts_before
        })
      }
    }
  }
}
</script>

<style></style>
